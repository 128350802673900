(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/racing/assets/javascripts/resolve-any-racing-input.js') >= 0) return;  svs.modules.push('/components/utils/racing/assets/javascripts/resolve-any-racing-input.js');
"use strict";


let productIds;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  productIds = trinidad.components.require('utils', 'products').productIds;
} else {
  productIds = svs.utils.products.productIds;
}
function resolveAnyRacingInput(anyInput) {
  switch (anyInput) {
    case 'trav-galopp':
    case 'travochgalopp':
    case 'trav&galopp':
    case 'trav & galopp':
    case 'Trav och galopp':
    case 'racing':
    case 'rx':
    case 'hastar':
    case 'hästar':
    case productIds.RACING:
      return true;
    default:
      return false;
  }
}
if (svs.isServer) {
  module.exports = resolveAnyRacingInput;
} else {
  svs.utils.racing.resolveAnyRacingInput = resolveAnyRacingInput;
}

 })(window);